
          @import "src/styles/_mixins.scss";
        
@import '../../../styles/variables';

.root {
  position: relative;
  height: 100%;

  .withholding_subText {
    margin: auto 0;
    font-size: 16px;
    font-weight: 500;
  }

  .entityFormGrid {
    input {
      max-width: 100px !important;
    }
    svg {
      min-width: 20px !important;
    }
  }
  .prospectedButtonContainer {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
