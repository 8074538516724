
          @import "src/styles/_mixins.scss";
        
.container {
  display: flex;
  align-items: center;

  span {
    color: black;
    font-weight: 600;
  }
}
