
          @import "src/styles/_mixins.scss";
        
$spinnerSize: 30;
$spinnerSizeXL: 80;

.spinner {
  width: $spinnerSize + px;
  height: $spinnerSize + px;
  x: 0px;
  y: 0px;
  viewbox: 0 0 $spinnerSize $spinnerSize;

  @keyframes spinner {
    0% {
      stroke-dashoffset: (0.66 * $spinnerSize);
      transform: rotate(0deg);
    }
    50% {
      stroke-dashoffset: (3.14 * $spinnerSize);
      transform: rotate(720deg);
    }
    100% {
      stroke-dashoffset: (0.66 * $spinnerSize);
      transform: rotate(1080deg);
    }
  }
  circle {
    fill: transparent;
    stroke: #243b53;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-dasharray: (3.14 * $spinnerSize);
    transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    animation: spinner 4s linear infinite;
  }
}

.spinner_xl {
  width: $spinnerSizeXL + px;
  height: $spinnerSizeXL + px;
  x: 0px;
  y: 0px;
  viewbox: 0 0 $spinnerSizeXL $spinnerSizeXL;

  @keyframes spinner {
    0% {
      stroke-dashoffset: (0.66 * $spinnerSizeXL);
      transform: rotate(0deg);
    }
    50% {
      stroke-dashoffset: (3.14 * $spinnerSizeXL);
      transform: rotate(720deg);
    }
    100% {
      stroke-dashoffset: (0.66 * $spinnerSizeXL);
      transform: rotate(1080deg);
    }
  }
  circle {
    fill: transparent;
    stroke: #243b53;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-dasharray: (3.14 * $spinnerSizeXL);
    transform-origin: (0.5px * $spinnerSizeXL) (0.5px * $spinnerSizeXL) 0;
    animation: spinner 4s linear infinite;
  }
}
