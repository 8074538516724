
          @import "src/styles/_mixins.scss";
        
.investmentBody {
  display: flex;
  min-height: 100vh;

  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  &.offeringValidError {
    height: 100vh;
    overflow: hidden;
  }
}
