
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables';

.share {
  display: flex;
  // background-color: yellow;
  font-size: 1.25rem;
  color: rgb(23, 66, 114);
  align-items: center;

  &__error {
    color: red;
    margin-left: 0.5rem;
    font-size: 16px;
  }
}

.root {
  background: $body-color;
  padding: 0 100px;
  padding-top: 65px;
  padding-bottom: 30px;
  z-index: 3;
  margin-left: auto;
  width: calc(100vw - 30%);

  @media screen and (max-width: 1275px) {
    width: calc(100vw - 35%);
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    //margin-top: auto;
    padding: 0px !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  @media screen and (max-width: 600px) {
    label {
      font-size: 14px !important;
    }
  }

  .investmentInformation_paragraph:last-child {
    margin-left: 5px !important;
  }

  .investorInformation_backButton {
    color: $light-text-color !important;
    background: unset !important;
    text-transform: inherit;
    font-size: 16px;
    outline-width: 0;
    background: transparent;
    padding-left: 0px !important;

    span {
      font-weight: 600;
    }

    svg {
      margin: 0 10px;
    }

    @media screen and (max-width: 600px) {
      span {
        display: none;
      }
    }
  }

  .titleHeader {
    @media screen and (max-width: 600px) {
      background: #fff;
      padding: 20px 35px;
      display: flex;
      align-items: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      .nextStep {
        display: block;
      }
    }

    @media screen and (max-width: 525px) {
      padding: 20px 15px !important;
      margin: 0 -12px;
      width: calc(100% + 24px);
      border-radius: 0;
      h1 {
        font-size: 16px;
      }
      button {
        padding: 0px !important;
        min-width: 35px !important;
        margin-right: 5px;
      }
      svg {
        width: 20px !important;
        padding: 0px !important;
      }
    }
  }
  .personalInformation_container {
    @media screen and (max-width: 600px) {
      padding: 15px;
    }
  }
}

.container {
  position: relative;
  height: 100%;

  .investmentInformation_titleText {
    color: $dark-text-color;
    font-size: 24px;
    font-weight: 600;
  }
  .investmentInformation_paragraph {
    color: $light-text-color;
    font-size: 18px;

    @media screen and (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  .investmentInformation_paragraph_red {
    color: red;
    font-size: 18px;

    @media screen and (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  .investmentInformation_inputFields {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px;
    padding-right: 15px;
    border-radius: 4px;
    color: #627d98;
    height: 62px;
    width: 100%;
    margin-top: 12px;
    @media (min-width: 1000px) {
      max-width: fit-content;
      margin-top: calc(24px + 0.25rem);
    }
    &__small {
      padding: 3px;
      max-width: unset;
    }
    &__hasError {
      border-color: #f85874 !important;
      svg * {
        fill: #f85874 !important;
      }
    }

    input {
      padding: 5px;
      outline-width: 0;
      color: #243b53;
      width: 100%;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:disabled {
        background: #ffffff;
      }
    }
    svg {
      margin: 0 10px;
    }

    p {
      margin-top: auto;
      margin-bottom: auto;

      @media screen and(max-width:600px) {
        font-size: 14px !important;
      }
    }
  }

  .investmentInformation_minimum__hasError {
    color: #f85874 !important;
  }
}

.confirmInvestment {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .personalInformation_container {
    height: 100px;
  }
}

.fundTransfering {
  position: relative;
  height: 100%;
  .fundTransferOption_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;
    outline-width: 0;
    svg {
      margin-left: 65px;
    }
  }
  .fundTransferOption_nextBtn:hover {
    background: $active-yellow-color;
  }
  .fundTransfering_choicesFundDiv {
    border-radius: 8px;
    cursor: pointer;
    transition: 350ms ease;
    .fundTransfering_titleChoice {
      color: $dark-text-color;
      font-size: 16px;
      font-weight: 600;
      //margin-top: 10px;
    }
    .fundTransfering_checkIcon {
      color: $active-yellow-color;
    }
    .fundTransfering_roundIcon {
      color: $light-text-color;
    }
  }
  .fundTransfering_choicesFundDiv:hover {
    border: 1px solid $active-yellow-color !important;
    .fundTransfering_checkIcon {
      display: block !important;
    }
    .fundTransfering_roundIcon {
      display: none !important;
    }
  }
  .fundTransfering_Button {
    color: $light-text-color;
    font-weight: 600;
    text-transform: inherit;
    font-size: 16px;
    outline-width: 0;
  }
  .fundTransfering_title {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 24px;
  }
  .fundTransfering_paragraph,
  .fundTransfering_paragraphFunds,
  .fundTransfering_paragraphBank {
    color: $light-text-color;
    font-weight: 400;
  }
  .fundTransfering_paragraphBank {
    margin: 0px !important;
  }
  .fundTransfering_paragraph {
    font-size: 18px;
  }
  .fundTransfering_paragraphFunds {
    font-size: 16px;
  }
}

.fundTransfering_checkIcon {
  color: $active-yellow-color;
}
.fundTransfering_roundIcon {
  color: $light-text-color;
}
.orText {
  min-width: fit-content;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.investmentInformation_nextBtn {
  background: $active-yellow-color;
  border-radius: 6px;
  width: fit-content;
  padding: 9px;
  color: #243b53;
  font-weight: 600;
  padding-left: 75px;
  text-transform: inherit;
  font-size: 18px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;

    h3 {
      margin: 0 10px;
    }
  }
  svg {
    margin-left: 65px;

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
}
.investmentInformation_nextBtn:hover,
.investmentInformation_nextBtn:focus {
  background: $active-yellow-color;
  border: none;
  outline-width: 0;
}
