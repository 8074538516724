
          @import "src/styles/_mixins.scss";
        
@import '../../../styles/variables';
.notAccredited {
  margin-top: 20px;
}
.notAccreditedButton {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: block;
  }

  button {
    color: white;
    padding-left: 0;
    padding: 10px;
  }
  &__subtext {
    padding-left: 10px;

    @media screen and (max-width: 600px) {
      padding-left: 0px;
      padding-top: 20px;
      text-align: center;
    }
  }
}

.root {
  input[type='radio'],
  label {
    cursor: pointer;
  }
  .annualIncome {
    margin: 20px 0;
  }
  .title {
    color: #243b53;
    font-weight: 600;
    font-size: 24px;
    margin: 0px !important;
  }
  .mainParagraph {
    color: #243b53;
    font-size: 17px;
    padding: 10px 0;
    font-weight: 500;
    margin: 0px !important;
  }
  .paragraph {
    color: #627d98;
    font-size: 17px;
    padding: 10px 0;
    margin: 0px !important;
  }
  .statement {
    label {
      margin-left: 5px;
      color: #627d98;
    }
  }
  .containerAccredited {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    label {
      margin-left: 5px;
    }
  }
  .backBtn {
    color: $light-text-color !important;
    background: unset !important;
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    outline-width: 0;
    background: transparent;
    padding: 9px !important;

    @media screen and (max-width: 600px) {
      width: fit-content !important;
    }

    h3 {
      font-size: 16px;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    svg {
      margin-left: 0px !important;
    }
  }
  .nextBtn {
    width: 100%;
    margin: 20px 0px 25px 0 !important;
    padding-left: 0px !important;
    svg {
      margin-left: 10px !important;
    }
  }
  .nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    width: fit-content;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding: 9px 75px !important;
    text-transform: inherit;
    font-size: 18px;
    @media (max-width: 768px) {
      margin: 0 !important;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    svg {
      margin-left: 65px;
    }
  }
  .nextBtn:hover,
  .nextBtn:focus {
    background: $active-yellow-color;
    border: none;
    outline-width: 0;
  }
  .titleText {
    color: $dark-text-color;
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 525px) {
      font-size: 16px !important;
    }
  }
  .labelText {
    color: #627d98;
    font-size: 17px;
    padding: 0 10px;
  }
  .container {
    margin-right: 4px;
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 20px;
    }
    .mainParagraph,
    .paragraph {
      font-size: 14px !important;
    }
    .titleHeader {
      background: #fff;
      //padding: 20px 35px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 15px !important;
      margin: 0 -12px;
      width: calc(100% + 24px);
      border-radius: 0;
      .nextStep {
        display: block;
      }
    }
  }
  @media screen and (max-width: 525px) {
    .titleHeader {
      padding: 20px 15px !important;
      button {
        padding: 0px !important;
        min-width: 35px !important;
        margin-right: 5px;
      }
      p {
        font-size: 8px !important;
      }

      svg {
        width: 20px !important;
        padding: 0px !important;
      }
    }
  }
}
