
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables.scss';

.root {
  background: #f6fafc;
  padding: 65px 110px 30px;
  z-index: 3;
  margin-left: auto;
  width: calc(100vw - 30%);

  @media screen and (max-width: 1275px) {
    width: calc(100vw - 35%);
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  label {
    color: #627d98 !important;
    text-transform: capitalize;
  }

  .buttonOptionInvestor,
  button {
    @media screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-left: 0px;
      h3 {
        margin: 0 10px;
      }
      svg {
        margin-left: 0px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex: 1;
  }

  @media screen and (max-width: 600px) {
    padding: 0 12px;
  }

  .titleHeader {
    .logoutBtn {
      span {
        font-weight: 600;
      }
    }
    @media screen and (max-width: 600px) {
      background: #fff;
      padding: 20px 35px;
      display: flex;
      align-items: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      .logoutBtn {
        margin-left: 10px;
        width: fit-content;
        span {
          display: none !important;
        }
      }
    }
    @media screen and (max-width: 525px) {
      padding: 20px 15px !important;
      margin: 0 -12px;
      width: calc(100% + 24px);
      border-radius: 0;

      button {
        padding: 0 !important;
        min-width: 35px !important;
      }

      svg {
        width: 20px !important;
        padding: 0 !important;
      }

      .title {
        font-size: 16px !important;
      }
    }
    .title {
      color: $dark-text-color;
      font-weight: 600;
      font-size: 24px;
    }
  }
  .logoutBtn {
    color: $light-text-color !important;
    background: unset !important;
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    outline-width: 0;
    padding: 9px !important;
    svg {
      margin-left: 0 !important;
      margin-right: 8px !important;
    }
  }
  .dataContainer {
    @media screen and (max-width: 1275px) {
      overflow: auto;
    }

    @media screen and(max-width:525px) {
      p,
      span,
      label {
        font-size: 14px !important;
      }
    }
  }
  .buttonWrapper {
    margin-bottom: 125px;
    margin-top: 40px;
  }
  .paragraph {
    color: $light-text-color;
    font-weight: 400;
    font-size: 18px;
  }
  .investmentOptions {
    .checkIcon {
      color: $active-yellow-color;
    }
    .uncheckedIcon {
      color: $light-text-color;
    }
    .checkedCircle {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #627d9886;
    }
    .investmentTypeCard {
      background: #fff;
      margin-right: 25px;
      width: 100%;
      max-width: 215px;
      border-radius: 8px;
      transition: 350ms ease;
      cursor: pointer;

      @media screen and (max-width: 1450px) {
        width: 100%;
        max-width: 100%;
      }

      .cardTitle {
        color: $dark-text-color;
        font-weight: 600;
        font-size: 16px;
      }
      .cardSubtext {
        margin: 0px !important;
        font-size: 14px;
        color: $light-text-color;
        padding-top: 10px;
      }
    }
    .investmentTypeCard:hover {
      .checkedCircle {
        display: none !important;
      }
      .checkIcon {
        display: block !important;
      }
    }
  }
}
