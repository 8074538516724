
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables';

.title {
  font-weight: 600;
  text-decoration: underline;
}

.amlFrom {
}

.amlTextContainer {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #627d98;
  font-size: 18px;
}

.notes {
  color: $light-text-color;
  font-size: 18px;
}

.mobile_aml {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.root {
  position: relative;
  background: #f6fafc;
  padding: 0px 100px 30px;
  z-index: 3;
  margin-left: auto;
  width: calc(100vw - 30%);

  @media screen and (max-width: 1275px) {
    width: calc(100vw - 35%);
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 0px;
  }

  .fileName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
  }

  .fileContainer {
    width: 100%;

    .typeText {
      font-weight: 700;

      &.aml {
        left: 28px;
        bottom: 20px;
      }

      &.document {
        left: 28px;
        bottom: 20px;
      }

      @media screen and (max-width: 455px) {
        font-size: 13px;
        &.aml {
          left: 21px;
          bottom: 15px;
        }

        &.document {
          left: 22px;
          bottom: 15px;
        }
      }
    }

    @media screen and (max-width: 525px) {
      span {
        font-size: 16px;
      }
    }

    .trashIcon {
      display: none;
    }
  }

  .backButton {
    color: $light-text-color !important;
    background: unset !important;
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    outline-width: 0;
    padding: 9px !important;
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      span {
        display: none;
      }
    }

    svg {
      margin-left: 0 !important;
      margin-right: 8px !important;
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  .successInvestment {
    @media screen and (max-width: 600px) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .reactDropzone {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 525px) {
      p,
      span,
      ul,
      li {
        font-size: 16px;
      }
    }

    .icon_upload {
      position: absolute;
      opacity: 1;
      transition: 500ms ease-in-out;
    }

    .icon_upload.notActive {
      opacity: 0;
      transform: translateY(250%);
    }

    .icon_uploadCloud {
      position: absolute;
      transform: translateY(-250%);
      opacity: 0;
      transition: 500ms ease-in-out;
    }

    .icon_uploadCloud.active {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .titleHeader {
    @media screen and (max-width: 600px) {
      .titleHeaderTitleButton {
        display: flex;
        align-items: center;
      }

      .title {
        margin-top: 0px !important;
      }
    }

    @media screen and (max-width: 525px) {
      p {
        font-size: 14px;
      }

      h1 {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 600px) {
      background: transparent;
      padding: 25px 0 0 20px;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      p {
        display: none !important;
      }

      .nextStep {
        display: block;
      }
    }
    @media screen and (max-width: 525px) {
      padding: 15px 30px 0 !important;
      margin: 0 -12px;
      width: calc(100% + 24px);
      border-radius: 0;
      button {
        padding: 0 !important;
        min-width: 35px !important;
        margin-right: 0px;
      }
      svg {
        width: 20px !important;
        padding: 0 !important;
      }
    }
  }
  .completed {
    align-items: center;
    font-size: 14px;
    margin: 0 !important;
    font-weight: 600;
    display: none;
    color: $light-text-color;
    .checkCircle {
      color: $active-yellow-color;
      margin-left: 2px;
      font-size: 20px;
      margin-top: -5px;
    }
  }
  .title {
    color: $dark-text-color;
    font-size: 24px;
    font-weight: 600;
  }
  .titleCriteria {
    font-size: 18px;
    color: $dark-text-color;
    text-decoration: underline;
    font-weight: bold;
  }

  .criteriaPoints {
    li {
      margin-left: 20px;
    }
  }
  .successContainer {
    height: 100%;
    @media screen and (max-width: 525px) {
      ul {
        li {
          font-size: 14px;
        }
      }
    }
    @media screen and (max-width: 600px) {
      flex: 1;
      padding: 15px 20px 0 !important;
    }
  }
  .text {
    color: $light-text-color;
    font-size: 18px;
    margin: 0 !important;
  }
  .investAgain {
    background: $dark-text-color !important;
    font-size: 18px !important;
    color: #fff !important;
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    outline-width: 0;
    margin-top: 15px !important;
    font-weight: 600 !important;
    &:hover {
      background: $dark-text-color;
    }
    svg {
      margin: 0 10px;
    }
  }
  .autograph {
    font-family: 'Homemade Apple', cursive;
  }
}
