
          @import "src/styles/_mixins.scss";
        
$white: #fff;

.logo_container {
  display: flex;
  width: 130px;
  // background-color: blue;
  justify-content: flex-start;
}

.sideBar {
  background: #fff;
  padding-top: 35px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  left: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  width: calc(100vw - 70%);

  // Media screen (1275 pixels)
  @media screen and (max-width: 1275px) {
    width: calc(100vw - 65%);
  }

  // Media screen (900 pixels)
  @media screen and (max-width: 1000px) {
    display: none;
  }

  .stepper {
    cursor: default;
    position: relative;
    display: flex;
    align-items: center;
    .stepper_text_title {
      margin-bottom: -15px;
      p {
        font-size: 12px;
        font-weight: 500;
        color: #627d98;
      }
      h3 {
        color: #627d98;
        font-weight: 500;
      }

      .stepper_title_active {
        color: #153e68;
        font-weight: 600;
      }
      .stepper_paragraph_active {
        font-weight: 600;
      }
    }
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
    .circle {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      background: #ececec;
      border-radius: 50%;
      margin: 25px 0;
      margin-right: 15px;
      z-index: 4;
    }

    .circle_active,
    .circle_active::before {
      background: #ffa400;
    }
    .circle_active::before {
      background: #ffa400 !important;
      margin-top: 28px;
      height: 50px !important;
    }

    .circle_second_active {
      background: #ffa400 !important;
    }

    .circle_second {
      width: 15px;
      height: 15px;
      background: #59748a;
      border-radius: 50%;
    }
    .circle::before {
      content: '';
      height: 100px;
      width: 5px;
      background: #ececec;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 5px;
      z-index: -1;
    }

    .circle_last::before {
      display: none;
    }
  }
}
