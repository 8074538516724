
          @import "src/styles/_mixins.scss";
        
.account {
  // background-color: yellow;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @include respond(phone) {
    justify-content: space-between;
  }
  &__title {
    color: #627d98;

    padding-bottom: 10px;
  }
  &__radios {
    // background-color: violet;
    // background-color: blue;
    display: flex;
    padding: 0.5rem;
    &__saving {
      display: flex;
      flex: 1;
      @include respond(phone) {
        flex: 1;
      }
    }
    &__checking {
      display: flex;
      flex: 1;
      //   margin-left: 90px;
      @include respond(tab-land) {
      }
    }
    label {
      //   background-color: yellow;
    }
    // background-color: yellow;
  }
}

//flex ml-3 items-center
