
          @import "src/styles/_mixins.scss";
        
.TransactionTypes {
  display: flex;
  margin-top: 40px;
  @include respond(tab-land) {
    flex-wrap: wrap;
  }
  @include respond(phone) {
    flex-direction: column;
    flex-wrap: unset;
  }
}
