
          @import "src/styles/_mixins.scss";
        
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    cursor: pointer;
    margin-top: 20px;
    text-decoration: underline orange;
  }
}
.button {
  font-size: 16px;
  background-color: #174272;
  color: white;
  width: 200px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: unset;
}
