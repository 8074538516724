
          @import "src/styles/_mixins.scss";
        
.stepper {
  color: #627d98;
  font-weight: 600;
  font-size: 12px !important;
  text-transform: uppercase;
  display: none;
  margin: 0 !important;

  @media screen and (max-width: 600px) {
    display: block;
  }

  @media screen and (max-width: 525px) {
    font-size: 8px !important;
  }
}
