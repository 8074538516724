
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables.scss';

.root {
  :global {
    label {
      color: $light-text-color;
    }
    .rselect__control {
      padding: 2px;
      border: 1px solid transparent;
      box-shadow: none;
      &:hover {
        border: 1px solid #ffa400 !important;
        box-shadow: none;
      }
    }
    .rselect__control--menu-is-open {
      border: 1px solid #ffa400 !important;
    }
    .rselect__option.rselect__option--is-selected {
      background: #ffa400 !important;
    }
    .rselect__option.rselect__option--is-focused {
      background: #fff4e0;
    }
  }
}
