
          @import "src/styles/_mixins.scss";
        
.successContainer {
  font-size: 18px;
  color: #627d98;
  @include respond(phone) {
    margin: 20px;
  }
}
.text {
  margin-bottom: 20px;
}

.autograph {
  font-family: 'Homemade Apple', cursive !important;
}

.backButton {
  display: flex;
  align-items: center;

  &__desktopStatus {
    color: black;
    font-weight: 600;
    margin-top: 10px;
    font-size: 24px;
    @include respond(phone) {
      display: none;
    }
  }
}

.socialLinkWrapper {
  display: flex;
  justify-content: center;
}

.videoPlayerWrapper {
  width: auto;
  height: 488px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    width: unset;
    height: unset;
  }
}

.videoPlayer {
  width: 100% !important;
  height: 100% !important;
}
