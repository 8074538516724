
          @import "src/styles/_mixins.scss";
        
.mobileVerification {
  display: none;
  @include respond(phone) {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
  }
}
.backButton {
  display: flex;
  align-items: center;
  &__click {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  @media screen and (min-width: 37.5em) {
    margin-top: 50px;
  }

  &__mobileStatus {
    font-weight: 600;
    margin-left: 10px;

    @media screen and (min-width: 37.5em) {
      display: none;
    }
  }

  &__desktopStatus {
    font-weight: 600;
    margin-top: 10px;
    font-size: 24px;
    @include respond(phone) {
      display: none;
    }
  }
  @include respond(phone) {
    display: flex;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: white;
  }
  &__text {
    cursor: pointer;
    color: #627d98;
    margin-left: 10px;
    font-weight: 600;
    @include respond(phone) {
      display: none;
    }
  }
}
.investmentReference {
  font-weight: 600;
  font-size: 24px;
  span {
    color: #ffa400;
  }

  @include respond(phone) {
    font-size: 16px;
  }
}
.paymentStatus {
  @include respond(phone) {
    padding: 20px;
  }
}

.checkCircle {
  color: #ffa400;
  font-size: 14px;
  display: none;
  margin-left: 5px;

  @media screen and (max-width: 600px) {
    margin-left: 10px;
    display: flex;
  }
}

.paymentInfo {
  margin-top: 10px;
  color: #627d98;
  font-size: 18px;
  @include respond(phone) {
    font-size: 14px;
  }
  &__details {
    span {
      color: #ffa400;
      text-decoration: underline;
    }
  }
  &__sincerely {
    margin-top: 10px;
  }
  &__paolo_tiraman {
    margin-top: 10px;
    font-weight: 400;
    font-family: 'Homemade Apple', cursive !important;
  }
}

.ach {
  &__title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: rgb(23, 66, 114);
    @include respond(phone) {
      font-size: 18px;
    }
  }
  &__detail {
    color: rgb(23, 66, 114);
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    span {
      color: #627d98;
    }
  }
}

.loaderContainer {
  margin-top: 20px;

  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  //   background-color: red;
  &__text {
    color: #627d98;

    margin-bottom: 20px;
    &__title {
      &::after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
      }
    }
    &__sub {
      margin-top: 10px;
      &::after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
      }
    }
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
