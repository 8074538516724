
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: 6;
  backdrop-filter: blur(6px);

  .modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: flex-start;
    border: 1px solid transparent;

    @media screen and (max-width: 675px) {
      position: static;
      transform: none;
      margin: 0 auto;
      padding: 15px;
    }

    .closeCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: rgba(0, 0, 0, 0.35);
      color: #fff;
      border-radius: 50%;
      font-size: 20px;
      margin-left: 15px;
      cursor: pointer;

      @media screen and (max-width: 675px) {
        display: none;
      }
    }

    .modal {
      background: #fff;
      flex: 1;
      border-radius: 8px;
      overflow: hidden;
      max-height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;

      .modalHeader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        border-bottom: 1px solid #e7e8ec;

        .icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #222222;
          stroke-width: 3;
          margin-right: 15px;
          cursor: pointer;
        }

        .paragraph {
          color: #222222;
          font-size: 16px;
          font-weight: 600;
          margin: 0px;
          padding: 0px;
        }
      }

      .modalContent {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: calc(100% - 280px);
        padding: 0 24px;
        justify-content: center;
        align-items: center;
        display: flex;

        &::-webkit-scrollbar {
          width: 7px;
          margin: 10px 14px 10px 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 2px solid #505050;
          background-color: #505050;
          padding: 5px;
        }
      }
    }
  }
}

.modalForm {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .modalExample {
    display: flex;
    flex-direction: column;
    width: 100%;

    .input {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        margin: 15px 0;
        color: #acb5bd;
        font-weight: 500;
      }

      input {
        width: 100%;
        border: none;
        outline-width: 0;
        background: #f7f8fa;
        border-radius: 6px;
        padding: 15px;
      }
    }

    .input:last-child {
      margin-bottom: 35px;
    }
  }
}
