
          @import "src/styles/_mixins.scss";
        
.title {
  font-weight: 600;
  text-decoration: underline;
}

.amlFrom {
}

.amlTextContainer {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #627d98;
  font-size: 18px;
}

// $bg: #f6fafc;

// .quote {
//   display: inline-block;

//   margin: 1em;
//   overflow: hidden;
//   blockquote {
//     color: #243b53;
//     background-color: $bg;
//     border: solid 2px #ffa400;
//     display: inline-block;
//     margin: 0;
//     padding: 1em;
//     position: relative;
//     &:before {
//       background-color: $bg;
//       bottom: -10%;
//       content: '';
//       left: 0;
//       position: absolute;
//       right: 0;
//       top: -10%;
//       transform: rotate(-15deg) skew(5deg);
//     }
//     cite {
//       display: block;
//       font-style: italic;
//       text-align: right;
//       &:before {
//         content: '- ';
//       }
//     }
//     > * {
//       position: relative;
//       z-index: 1;
//     }
//   }
// }
