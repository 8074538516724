
          @import "src/styles/_mixins.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  font-family: 'Lato', sans-serif !important;
}

h1 {
  @apply text-2xl;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}

p {
  @apply mb-8;
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-85 {
  background-color: rgba(255, 255, 255, 0.85);
}

.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.z-9998 {
  z-index: 9998;
}

.z-9999 {
  z-index: 9999;
}

.bg-softblue {
  background-color: #f5fafc;
}

.flag-dropdown {
  display: none !important;
}

@screen md {
  .md-min-h-auto {
    min-height: auto !important;
  }
}

@tailwind components;

/* Custom Component Classes are added here to avoid Specificity Issues */

@tailwind utilities;

@layer utilities {
  .w-fit-content {
    width: fit-content;
  }

  .extra-small-text {
    font-size: 14px;
  }

  .padding-15 {
    padding: 15px;
  }
}

/* New Custom Utilities are added here to avoid Specificity Issues */
