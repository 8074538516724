
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables.scss';
.root {
  background: $active-yellow-color;
  border-radius: 6px;
  padding: 9px;
  color: #243b53;
  font-weight: 600;
  padding-left: 75px;
  text-transform: inherit;
  font-size: 18px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 65px;
  }

  &:hover,
  &:focus {
    background: $active-yellow-color;
    border: none;
    outline-width: 0;
  }
}
