
          @import "src/styles/_mixins.scss";
        
.overlay {
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  &__title {
    font-size: 20px;
    font-weight: 500;
  }
  &__subtitle {
    margin-top: 10px;
    span {
      color: orange;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}
