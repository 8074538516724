
          @import "src/styles/_mixins.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');
@import '../../styles/variables.scss';

$body-color: #f6fafc;
$light-text-color: #627d98;
$dark-text-color: #243b53;
$active-yellow-color: #ffa400;

.formBody {
  flex: 0.7;
  background: $body-color;
  padding: 0 100px;
  padding-top: 65px;
  padding-bottom: 30px;
  z-index: 3;
  overflow-x: hidden;

  .overlayCreditCard {
    backdrop-filter: blur(5px);
    opacity: 0.75;
  }

  .overlayCreditCard.red {
    background: #fdecea;

    a {
      color: #611a15 !important;
    }
  }

  .individual,
  .company,
  .IRA,
  .Trust,
  .investmentInformation {
    label {
      color: #243b53 !important;
    }
  }

  .investorInformation_backButton {
    color: $light-text-color !important;
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    outline-width: 0;
  }

  .successInvestment_spanCompleted {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0px !important;
    font-weight: 600;
    display: none;
    color: $light-text-color;
    .successInvestment_circleCheck {
      color: $active-yellow-color;
      margin-left: 2px;
      font-size: 20px;
      margin-top: -5px;
    }
  }
  .personalInformation_paragraphTitle {
    color: $light-text-color !important;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    margin: 0px !important;
    display: none;
  }
  .nextStep {
    margin-left: auto;
    margin-top: 5px;
    text-align: right;
    display: none;
    .nextStep__text {
      font-size: 12px;
      font-weight: 600;
      color: $light-text-color;
    }
    .nextStep__subtext {
      font-size: 16px;
      color: $light-text-color;
      font-weight: 500;
    }
    p {
      margin: 0px !important;
    }
  }
  .personalInformation_datePickerRoot {
    margin-top: 0.5rem !important;
    border: 1px solid #e2e8f0 !important;
    padding: 0.5rem 0.5rem 0.5rem 0 !important;
    border-radius: 0.25rem !important;
    &__hasError {
      border-color: #f85874 !important;
    }
    :global {
      .MuiFormHelperText-root {
        display: none;
      }
    }
  }
  .personalInformation_datePickerInput {
    height: 24px;
    padding: 0 8px;
  }
}

.successInvestment {
  .successInvestment_backButton {
    text-transform: inherit;
    color: $light-text-color;
    font-weight: 600;
    font-size: 16px;
    outline-width: 0;
  }
  .successInvestment_investAgainButton {
    background: $dark-text-color;
    text-transform: inherit;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline-width: 0;
    margin-top: 15px;
    font-weight: 600;
    svg {
      margin: 0 10px;
    }
  }
  .successInvestment_investAgainButton:hover {
    background: $dark-text-color;
  }
  .successInvestment_title {
    color: $dark-text-color;
    font-size: 24px;
    font-weight: 600;
  }
  .successInvestment_paragraph {
    color: $light-text-color;
    font-size: 18px;
    margin: 0px !important;
  }
  .successInvestment_paragraphName {
    font-family: 'Homemade Apple', cursive;
  }
}

.confirmInvestmentSignature {
  position: relative;
  height: 100%;

  &__container {
    max-height: 42px;
  }
  ul {
    li {
      color: $dark-text-color;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }

    .confirmInvestmentSignature_lastList {
      margin-left: 120px;
    }
  }
}
.confirmInvestmentSignature_nextBtn {
  background: $active-yellow-color;
  border-radius: 6px;
  padding: 9px;
  color: #243b53;
  font-weight: 600;
  padding-left: 35px;
  text-transform: inherit;
  font-size: 18px;
  outline-width: 0;
  svg {
    margin-left: 20px;
  }
}
.confirmInvestmentSignature_nextBtn:hover {
  background: #ffa400;
}

.confirmInvestmentSignature_button {
  color: $light-text-color;
  outline-width: 0;
  font-weight: 600;
  text-transform: inherit;
  font-size: 16px;
}
.confirmInvestmentSignature_title {
  color: $dark-text-color;
  font-size: 24px;
  font-weight: 600;
}
.confirmInvestmentSignature_paragraph {
  color: $light-text-color;
  font-weight: 400;
  font-size: 16px;
}

.confirmInvestmentSignature_input {
  max-width: 460px;
  border-radius: 4px;
  outline-width: 0;
  padding: 8px;
  padding-left: 10px;
  color: $dark-text-color;
  font-weight: 500;
}

.switchLabel {
  position: relative;
  width: 32px;
  height: 18px;
  margin-bottom: -2px;
  label {
    color: $light-text-color;
  }
}

.switch__circle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bcccdc;
  transition: 0.4s;
  border-radius: 50px;
}

.switch__circle::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50px;
}

.subtitle {
  margin: auto 0;
  color: $light-text-color;
  font-size: 16px;
  font-weight: 500;
}

.switch {
  opacity: 0;
}

.switch:checked + .switch__circle {
  background: $active-yellow-color !important;
}

.switch:focus + .investmentInformation_roundedCircle {
  box-shadow: 0 0 1px $active-yellow-color;
}

.switch:checked + .switch__circle:before {
  transform: translateX(15px);
}

.confirmInvestment {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .personalInformation_container {
    height: 100px;
  }

  .confirmInvestment_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 35px;
    text-transform: inherit;
    font-size: 18px;
    outline-width: 0;
    svg {
      margin-left: 25px;
    }
  }
  .confirmInvestment_nextBtn:hover {
    background: #ffa400;
  }
  .confirmInvestment_button {
    font-size: 16px;
    color: $light-text-color;
    font-weight: 600;
    outline-width: 0;
    text-transform: inherit;
  }
  .confirmInvestment_title {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 24px;
  }
  .confirmInvestment_containerPrice {
    background: #f7f7f7;
    border-radius: 8px;
  }
  .confirmInvestment_titleContainer {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 16px;
  }
}

.fundTransfering {
  position: relative;
  height: 100%;
  .fundTransferOption_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;
    outline-width: 0;
    svg {
      margin-left: 65px;
    }
  }
  .fundTransferOption_nextBtn:hover {
    background: $active-yellow-color;
  }
  .fundTransfering_choicesFundDiv {
    border-radius: 8px;
    cursor: pointer;
    transition: 350ms ease;
    .fundTransfering_titleChoice {
      color: $dark-text-color;
      font-size: 16px;
      font-weight: 600;
      //margin-top: 10px;
    }
    .fundTransfering_checkIcon {
      color: $active-yellow-color;
    }
    .fundTransfering_roundIcon {
      color: $light-text-color;
    }
  }
  .fundTransfering_choicesFundDiv:hover {
    border: 1px solid $active-yellow-color !important;
    .fundTransfering_checkIcon {
      display: block !important;
    }
    .fundTransfering_roundIcon {
      display: none !important;
    }
  }
  .fundTransfering_Button {
    color: $light-text-color;
    font-weight: 600;
    text-transform: inherit;
    font-size: 16px;
    outline-width: 0;
  }
  .fundTransfering_title {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 24px;
  }
  .fundTransfering_paragraph,
  .fundTransfering_paragraphFunds,
  .fundTransfering_paragraphBank {
    color: $light-text-color;
    font-weight: 400;
  }
  .fundTransfering_paragraphBank {
    margin: 0px !important;
  }
  .fundTransfering_paragraph {
    font-size: 18px;
  }
  .fundTransfering_paragraphFunds {
    font-size: 16px;
  }
}

.investorCriteria {
  height: 100%;
  position: relative;
  .InvestorCriteria_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;
    outline-width: 0;
    svg {
      margin-left: 65px;
    }
  }
  .InvestorCriteria_nextBtn:hover {
    background: $active-yellow-color;
  }
  .investorCriteria_backButton {
    text-transform: inherit;
    color: $light-text-color;
    font-weight: 600;
    font-size: 16px;
    outline-width: 0;
  }
  .investorCriteria_title {
    font-weight: 600;
    color: $dark-text-color;
    font-size: 24px;
  }

  .investorCriteria_paragraph {
    color: $light-text-color;
    font-size: 18px;
  }
  .investorCriteria_citizenParagraph_container {
    color: $dark-text-color;
    font-size: 16px;
    font-weight: 600;
  }
  .investorCriteria_citizenParagraph {
    color: $light-text-color;
    font-size: 16px;
    font-weight: 400;
  }
  .investorCriteria_circle_inactive {
    background: $body-color;
    color: $light-text-color;
    border-radius: 50%;
    margin-left: 25px;
  }
  .investorCriteria_checkCircle {
    color: $active-yellow-color;
  }
  .investorCriteria_checkCircleChoice {
    color: $active-yellow-color;
  }
  .investorCriteria_citizenDiv {
    transition: 350ms ease;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
  }

  .investorCriteria_citizenAndDiv {
    border-radius: 8px;
    transition: 350ms ease;
    border: 1px solid transparent;
    cursor: pointer;

    p {
      margin: 0px !important;
    }
  }
}

.investorCriteria_citizenAndDiv:hover {
  border: 1px solid $active-yellow-color;
  .investorCriteria_checkCircle {
    display: block !important;
  }
  .investorCriteria_circle_inactive {
    display: none !important;
  }
}

.investorCriteria_citizenDiv:hover {
  border: 1px solid $active-yellow-color;
  .investorCriteria_checkCircleChoice {
    display: block !important;
  }
  .investorCriteria_circle_inactive {
    display: none !important;
  }
}

.investorInformation {
  height: 100%;
  position: relative;
  .investorInformation_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;

    svg {
      margin-left: 65px;
    }
  }

  .investorInformation_nextBtn:hover,
  .investorInformation_nextBtn:focus {
    background: $active-yellow-color;
    border: none;
    outline-width: 0;
  }

  .title {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 24px;
  }
  .investorInformation_paragraph {
    color: $light-text-color;
    font-weight: 400;
    font-size: 18px;
  }
  .investorInformation_investOptionsContainer {
    .investorInformation_checkIcon {
      color: $active-yellow-color;
    }
    .investorInformation_circleCheckOption {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #627d9886;
    }
    .investorInformation_invest_div {
      background: #fff;
      margin-right: 25px;
      width: 100%;
      max-width: 215px;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: 350ms ease;
      cursor: pointer;
      .investorInformation_titleInvest {
        color: $dark-text-color;
        font-weight: 600;
        font-size: 16px;
      }
      .investorInformation_paragraphInvest {
        margin: 0px !important;
        font-size: 14px;
        color: $light-text-color;
        padding-top: 10px;
      }
    }

    .investorInformation_invest_div:hover {
      border: 1px solid $active-yellow-color;
      .investorInformation_circleCheckOption {
        display: none !important;
      }
      .investorInformation_checkIcon {
        display: block !important;
      }
    }
  }
}

.addressInformation {
  height: 100%;
  position: relative;
  .addressInformation_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;
    svg {
      margin-left: 65px;
    }
  }

  .addressInformation_nextBtn:hover,
  .addressInformation_nextBtn:focus {
    background: $active-yellow-color;
    border: none;
    outline-width: 0;
  }
  .addressInformation_title {
    color: $dark-text-color;
    font-size: 22px;
    font-weight: 600;
  }
  .addressInformation_paragraph {
    color: $light-text-color;
    font-weight: 400;
    margin-top: 10px;
  }
  .addressInformation_input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    outline-width: 0;
    color: #243b53;
  }
  label {
    margin: 10px 0;
    color: $light-text-color;
  }
  .addressInformation_backButton {
    color: $light-text-color;
    text-transform: inherit;
    font-size: 16px;
    font-weight: bold;
    outline-width: 0;
  }
}

.container {
  position: relative;
  height: 100%;
  .dataContainer {
    @media screen and (max-width: 1275px) {
      padding: 0 20px;
      overflow: auto;
    }
  }
  .investmentInformation_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    width: fit-content;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 !important;
    }
    svg {
      margin-left: 65px;
    }
  }

  .investmentInformation_nextBtn:hover,
  .investmentInformation_nextBtn:focus {
    background: $active-yellow-color;
    border: none;
    outline-width: 0;
  }

  .backButton {
    color: $light-text-color;
    text-transform: inherit;
    font-size: 16px;
    font-weight: bold;
    outline-width: 0;
  }

  .investmentInformation_titleText {
    color: $dark-text-color;
    font-size: 24px;
    font-weight: 600;
  }

  .investmentInformation_paragraph {
    color: $light-text-color;
    font-size: 18px;
  }

  .investmentInformation_switch {
    position: relative;
    width: 32px;
    height: 18px;
  }

  .investmentInformation_roundedCircle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bcccdc;
    transition: 0.4s;
    border-radius: 50px;
  }
  .investmentInformation_checkBox {
    opacity: 0;
  }

  .investmentInformation_checkBox:checked
    + .investmentInformation_roundedCircle {
    background-color: $active-yellow-color;
  }

  .investmentInformation_checkBox:focus + .investmentInformation_roundedCircle {
    box-shadow: 0 0 1px $active-yellow-color;
  }

  .investmentInformation_checkBox:checked
    + .investmentInformation_roundedCircle:before {
    transform: translateX(15px);
  }

  .investmentInformation_roundedCircle::before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50px;
  }

  .investmentInformation_inputFields {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px;
    padding-right: 15px;
    border-radius: 4px;
    color: #627d98;
    height: 62px;
    width: 100%;
    margin-top: 12px;
    @media (min-width: 911px) {
      max-width: fit-content;
      margin-top: calc(24px + 0.25rem);
    }
    &__small {
      padding: 3px;
      max-width: unset;
    }
    &__hasError {
      border-color: #f85874 !important;
      svg * {
        fill: #f85874 !important;
      }
    }

    input {
      padding: 5px;
      outline-width: 0;
      color: #243b53;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:disabled {
        background: #ffffff;
      }
    }
    svg {
      margin: 0 10px;
    }

    p {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .investmentInformation_shareInfo {
    font-size: 18px;
    color: #243b53;
    margin-top: 16px;
    margin-bottom: 0;
  }
  .investmentInformation_minimum__hasError {
    color: #f85874 !important;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1;
  display: none;
}

.personalInformation {
  position: relative;
  height: 100%;
  .backButton {
    margin-left: -5px;
    outline-width: 0;
    color: $light-text-color;
    text-transform: inherit;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    p {
      margin: 0px !important;
    }
  }
  .personalInformation_nextBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 9px;
    color: #243b53;
    font-weight: 600;
    padding-left: 75px;
    text-transform: inherit;
    font-size: 18px;
    svg {
      margin-left: 65px;
    }
  }

  .personalInformation_nextBtn:hover,
  .personalInformation_nextBtn:focus {
    background: $active-yellow-color;
    border: none;
    outline-width: 0;
  }
  .personalInformation_title_text {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  .personalInformation_paragarph {
    color: $light-text-color;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }

  .personalInformation_form {
    label {
      color: $light-text-color;
    }
    input {
      color: #243b53;
      padding-left: 15px;
    }
    select {
      color: #243b53;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 99%;
      background-position-y: 7px;
      padding-left: 15px;
      padding-right: 18px;
    }
  }
}

// Media screen (1275 pixels)
@media screen and (max-width: 1275px) {
  .formBody {
    flex: 0.65 !important;
  }
}

// Media screen (1050 pixels)
@media screen and (max-width: 950px) {
  .formBody {
    flex: 0.5;
  }
}

// Media screen (900 pixels)
@media screen and (max-width: 900px) {
  .formBody {
    flex: 1 !important;
  }
}

// Media screen (850 pixels)
@media screen and (max-width: 1450px) {
  .investorInformation_invest_div {
    max-width: 100% !important;
  }
}

// Media screen (600 pixels)
@media screen and (max-width: 600px) {
  .formBody {
    height: 100vh !important;
    margin-top: auto;
    padding: 0px !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    overflow: hidden !important;
  }
  .personalInformation,
  .container,
  .addressInformation,
  .investorInformation,
  .investorCriteria,
  .fundTransfering,
  .confirmInvestment,
  .confirmInvestmentSignature {
    display: flex;
    flex-direction: column;
  }
  .successInvestment {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .personalInformation_container {
    flex: 1;
    overflow: scroll !important;
    padding-top: 15px !important;
  }
  .successInvestment_spanCompleted {
    display: block !important;
  }
  .personalInformation_paragraphTitle {
    display: block !important;
  }
  .overlay {
    display: block;
  }
  .titleHeader {
    background: #fff;
    padding: 20px 35px;
    display: flex;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    align-items: center;
    .nextStep {
      display: block;
    }
  }

  .personalInformation_container {
    padding: 0 20px;
  }
  ul {
    .confirmInvestmentSignature_lastList {
      margin-left: 0px !important;
      margin-top: 0px;
    }
  }

  .confirmInvestmentSignature_input {
    max-width: 100% !important;
  }
  .personalInformation_nextBtn,
  .investmentInformation_nextBtn,
  .addressInformation_nextBtn,
  .investorInformation_nextBtn,
  .InvestorCriteria_nextBtn,
  .fundTransferOption_nextBtn,
  .confirmInvestment_nextBtn,
  .confirmInvestmentSignature_nextBtn,
  .successInvestment_investAgainButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 25px 0 !important;
    padding-left: 0px !important;
    svg {
      margin-left: 10px !important;
    }
  }
  .backButton,
  .backButton,
  .addressInformation_backButtonm,
  .investorInformation_backButton,
  .addressInformation_backButton,
  .investorCriteria_backButton,
  .fundTransfering_Button,
  .confirmInvestment_button,
  .confirmInvestmentSignature_button,
  .successInvestment_backButton {
    margin-top: auto !important;
    margin-bottom: auto !important;
    p,
    b {
      display: none !important;
    }
    svg {
      margin-right: 0px !important;
    }
  }
}

@media screen and (max-width: 525px) {
  .personalInformation_paragraphTitle {
    font-size: 8px !important;
  }

  .personalInformation_paragarph,
  .investmentInformation_paragraph,
  .addressInformation_paragraph,
  .investorInformation_paragraph,
  .investorCriteria_paragraph,
  .investorCriteria_citizenParagraph,
  .investorCriteria_citizenParagraph_container,
  .fundTransfering_paragraph,
  .fundTransfering_paragraphFunds,
  .fundTransfering_titleChoice,
  .confirmInvestmentSignature_paragraph,
  .subtitle,
  .successInvestment_paragraph {
    font-size: 14px !important;
  }
  .confirmInvestmentSignature_paragraph {
    margin: 0px !important;
  }

  .confirmInvestmentSignature {
    ul {
      margin-top: 25px;
      li {
        font-size: 14px !important;
      }
    }
  }
  .successInvestment_spanCompleted {
    font-size: 13px !important;
  }
  .addressInformation_paragraph {
    margin: 0px !important;
  }
  .investmentInformation_paragraph:last-child {
    margin-left: 5px !important;
  }
  .formBody {
    label {
      font-size: 14px !important;
    }
  }
  .addressInformation_backButton {
    margin-left: -15px !important;
    margin-right: 0px !important;
  }
  .personalInformation_title_text,
  .investmentInformation_titleText,
  .addressInformation_title,
  .title,
  .investorCriteria_title,
  .fundTransfering_title,
  .confirmInvestment_title,
  .confirmInvestmentSignature_title,
  .successInvestment_title {
    font-size: 16px !important;
  }
  .investmentInformation_inputInvestments {
    input {
      max-width: 100px !important;
    }
    .investmentInformation_minimum {
      font-size: 12px !important;
      text-align: right;
      flex: 1;
    }
    .investmentInformation_minimum__hasError {
      color: #f85874 !important;
    }
    svg {
      min-width: 20px !important;
    }
  }

  .nextStep__text {
    font-size: 8px !important;
  }
  .nextStep__subtext {
    font-size: 12px !important;
  }
  .titleHeader {
    padding: 20px 15px !important;
    button {
      padding: 0px !important;
      min-width: 35px !important;
      margin-right: 5px;
    }
    svg {
      width: 20px !important;
      padding: 0px !important;
    }
  }
}

.loginForm {
  .loginForm__yellowBtn {
    background: $active-yellow-color;
    border-radius: 6px;
    padding: 8px 16px;
    color: #243b53;
    font-weight: normal;
    text-transform: inherit;
    font-size: 18px;
    &:hover,
    &:focus {
      background: $active-yellow-color;
      border: none;
      outline-width: 0;
    }
  }
}
.password_validation {
  ul {
    columns: 2;
    list-style: none;
    @media (max-width: 480px) {
      columns: 1;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    color: $light-text-color;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    p {
      margin-bottom: 0;
      margin-left: 8px;
    }
    &__passed {
      color: $active-yellow-color;
    }
  }
}
.fundTransfering_checkIcon {
  color: $active-yellow-color;
}
.fundTransfering_roundIcon {
  color: $light-text-color;
}
.orText {
  min-width: fit-content;
  @media (max-width: 768px) {
    width: 100%;
  }
}
