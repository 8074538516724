
          @import "src/styles/_mixins.scss";
        
.paymentCard {
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
  transition: 350ms ease;

  .title {
    color: #243b53;
    font-size: 16px;
    font-weight: 600;
  }
  .iconChecked {
    color: #ffa400;
    width: 25%;
    min-width: 20%;
  }
  .iconUnchecked {
    color: #627d98;
    width: 25%;
    min-width: 20%;
  }
  .description {
    color: #627d98;
    font-weight: 400;
    margin: 0px;
  }
}
