
          @import "src/styles/_mixins.scss";
        
.error {
  margin-top: 3rem;

  //   p {
  //     font-size: 30px;
  //   }
}
