
          @import "src/styles/_mixins.scss";
        
@import '../../styles/variables.scss';

.root {
  color: #243b53;

  label {
    color: $light-text-color;
  }
  .leftIcon {
    position: absolute;
    left: 12px;
    color: #2b527f;
    top: 50%;
    transform: translateY(-50%);
  }
  .rightIcon {
    position: absolute;
    right: 12px;
    color: #2b527f;
    top: 50%;
    transform: translateY(-50%);
  }

  .checkbox {
    position: relative;
    width: 32px;
    height: 18px;
    margin-bottom: -2px;

    input:checked + .toggle {
      background: $active-yellow-color !important;
    }

    input:focus + .investmentInformation_roundedCircle {
      box-shadow: 0 0 1px $active-yellow-color;
    }

    input:checked + .toggle:before {
      transform: translateX(15px);
    }

    .toggle {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #bcccdc;
      transition: 0.4s;
      border-radius: 50px;
    }

    .toggle::before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50px;
    }
  }
}
