
          @import "src/styles/_mixins.scss";
        
.nselect {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.error {
  color: 'red' !important;
}
.mui_label {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  color: black;
}
.mui_input {
  /* background-color: red; */
  padding: 9px;

  border-radius: 5px;
  border-width: 1px;
  border-radius: 0.25rem;
  outline: none;
  flex: 1;

  /* width: 100%; */
  color: black;
  font-size: 15px;
  margin-bottom: 0px;

  border-color: transparent;

  &-webkit-autofill,
  &-webkit-autofill:hover,
  &-webkit-autofill:focus {
    background-color: white;
  }
}
.mui_container {
  /* border-radius: 10px; */
  position: relative;
  border: 1px solid #e5e7eb;

  border-radius: 5px;
  /* background-color: yellow; */
  width: 100%;
  display: flex;
}
.mui-container:hover {
  border: 1px solid #ffa400;
}

.right {
  width: 40px;
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: white;
  /* background-color: red; */
  span {
    align-self: stretch;
    background-color: rgb(204, 204, 204);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
  }
}

.right_arrow_container {
  /* color: rgb(204, 204, 204); */
  display: flex;
  padding: 8px;
  transition: color 150ms ease 0s;
  box-sizing: border-box;
}
