
          @import "src/styles/_mixins.scss";
        
.checkboxContainer {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: right;
  float: right;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  @include respond(phone) {
    margin-right: 10px;
  }
}

.container {
  max-width: 20rem;
  float: right;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  @include respond(tab-port) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  @include respond(phone) {
    max-width: unset;
  }
}

.button {
  float: right;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  svg {
    margin-left: 50px;
  }
}

.subscription {
  color: #627d98;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 13px;
}
